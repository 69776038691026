<template>
    <div>
        <input hidden type="text" class="form-control" id="PpnTaxId" v-model="PpnTaxId">
        <div class="modal fade" id="taxModal" tabindex="-1" aria-labelledby="taxModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="taxModalLabel" class="font-weight-bold">{{ ModalTitle }}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Persentase (%)</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="Percentage" type="number" v-model="Percentage" class="font-weight-bold" @change="onChangePercentage()"/>
                                    <label id="errorPercentage" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>   
                            <div v-if="this.SaveType !== 'Add'">                         
                                <CRow>
                                    <CCol sm="4">
                                        <label class="form-label font-weight-bold">Default</label>
                                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                    </CCol>   
                                    <CCol sm="8">
                                        <input type="checkbox" v-model="Default" style="margin-top: 2%;"/>
                                        <label id="errorDefault" class="form-error" style="display: none; color: red;"></label>
                                    </CCol>                           
                                </CRow>
                            </div>
                            <br>
                            <label id="errorPpnTaxGeneral" class="form-error" style="display: none; color: red;"></label>
                            <br>

                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import PpnTaxService from '../Script/PpnTaxServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'PpnTaxForm',
    props: ['reload'],
    components: {
    },
    data: function () {
        return {
            ModalTitle : '',

            PpnTaxId : '',
            Percentage: 0,
            Default: false,

            Error: 0,
            SaveType: '',
        }
    },
    methods: {
        onChangePercentage(){
            this.Percentage = this.$globalfunc.percentValidate(this.Percentage);
        },
        addClick() {  
            $(".form-error").css("display", "none");
            $(".save-button").show();

            this.ModalTitle = 'Add Tax: ';   
            this.PpnTaxId = '';
            this.Percentage = 0;

            this.Default = false;

            this.SaveType = 'Add';
            window.$('#taxModal').modal('show');
        },
        editClick(taxData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            
            this.ModalTitle = 'Edit Tax: ';

            this.PpnTaxId = taxData.id;
            this.Percentage = taxData.percentage;
            
            this.Default = taxData.is_default;

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
            }
            
            window.$('#taxModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if (toString(this.Percentage) == '' || toString(this.Percentage) == null) {
                this.errorShow('errorPercentage');
            }

            if (this.Percentage > 100) {
                this.Error++;
                this.$swal("Error", "Persentase tidak boleh lebih besar dari 100", "error");
            }

            var oldData = await PpnTaxService.getIsDefaultValidation();
            var hashMap = [];
            oldData.forEach(el=>{
                hashMap[el.id]= el
            })
            
            if(hashMap[this.PpnTaxId].is_default == true &&  this.Default == false){
                this.Error++;
                this.$swal("Error", "Default tidak boleh di uncheck", "error");
            }
        },
        async saveClick() {
            this.inputValidation();
            
            if(this.Error == 0){
                //Add
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const taxData = {
                        percentage: this.Percentage,
                        is_default: this.Default
                    };

                    const variables = {
                        data : taxData
                    }

                    PpnTaxService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#taxModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const taxData = {
                        percentage: this.Percentage,
                        is_default: this.Default
                    };

                    const variables = {
                        id : parseInt(this.PpnTaxId),
                        data : taxData
                    }

                    PpnTaxService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#taxModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
